// slices/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetFeedApi } from '../../apis/api';
export const fetchfeed = createAsyncThunk(
    'user/fetchfeed',
    async (token) => {
        const response = await GetFeedApi(token);
        return response.data;
    }
);
const userSlice = createSlice({
    name: 'feed',
    initialState: {
        feed: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchfeed.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchfeed.fulfilled, (state, action) => {
                state.loading = false;
                state.feed = action.payload;
            })
            .addCase(fetchfeed.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});
export default userSlice.reducer;
