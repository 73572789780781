import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllReels } from "../redux/slices/reelSlice";
import moment from "moment";
import { FaCommentDots, FaHeart, FaShare } from "react-icons/fa";
import { reelLike } from "../apis/api";
import { videourl } from "../apis/host";
import AuthLayout from "./AuthLayout/AuthLayout";
import Login from "./AuthLayout/Login/Login";

const AllReels = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const Token = localStorage.getItem("TOKEN");

  useEffect(() => {
    if (Token) {
      dispatch(fetchAllReels(Token));
    }
  }, [dispatch, Token]);

  const reel = useSelector((state) => state?.reel?.reel?.data);

  const onVideoPress = () => {
    if (isVideoPlaying) {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    } else {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const handleReelLike = (reel_id) => {
    reelLike(reel_id, Token)
      .then((res) => {
        dispatch(fetchAllReels(Token));
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const toggleDescription = (index) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      {Token ? (
        <div className="Showreel">
          <div className="app__videos">
            {reel?.map((item2, index) => {
              const isExpanded = expandedDescriptions[index];
              const description = item2?.description || "";
              const truncatedDescription =
                description.length > 100 ? description.slice(0, 100) + "..." : description;

              return (
                <div className="videoContainer" key={index}>
                  <video
                    ref={videoRef}
                    onClick={onVideoPress}
                    className="videoCard__player"
                    src={`${videourl}${item2?.file_name}`}
                    alt="IG reel video"
                    width="100%"
                    loop
                  />
                  <div className="videoButtons">
                    <div className="likeButton">
                      <div
                        onClick={() => handleReelLike(item2.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item2?.reel_likes?.length === 0 ? (
                          <FaHeart color="white" size={20} />
                        ) : (
                          <FaHeart color="red" size={20} />
                        )}
                      </div>
                      <h6>{item2?.likes_count}</h6>
                    </div>
                    <div className="commentButton">
                      <FaCommentDots color="white" size={20} />
                      <h6>{item2?.comment_count}</h6>
                    </div>
                    <div className="shareButton">
                      <FaShare color="white" size={20} />
                      <h6>Share</h6>
                    </div>
                  </div>
                  <div className="videofooter">
                    <h6>
                      {isExpanded ? description : truncatedDescription}{" "}
                      {description.length > 100 && (
                        <span
                          onClick={() => toggleDescription(index)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {isExpanded ? "See less" : "See more"}
                        </span>
                      )}
                    </h6>
                    <div className="namedate">
                      <h6>{item2?.reel_user?.user_profile?.name}</h6>
                      <span>{moment(item2?.created_at).format("D MMMM YYYY")}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <AuthLayout>
          <Login />
        </AuthLayout>
      )}
    </>
  );
};

export default AllReels;
