import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button, Select, message, Modal } from "antd";
import "./EditProfile.scss";
import addBtn from "../../assest/addbtn.png";
import ServiceForm from "../ServiceForm/ServiceForm";
import { CityApi, GetSelfProfileAPi, StateApi, UpdateProfileApi } from "../../apis/api";
import { imageurl } from "../../apis/host";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
const { TextArea } = Input;
const EditProfile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fileInputRef = useRef(null);
  const Token = localStorage.getItem("TOKEN");
  const [userData, setUserData] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [form] = Form.useForm(); // Create form instance
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  const profile = useSelector((state) => state?.user?.profile?.data);
  useEffect(() => {
    form.setFieldsValue({
      name: profile?.user_profile?.name,
      username: profile?.user_name,
      email: profile?.email,
      mobile: profile?.user_profile?.mobile,
      state: profile?.user_profile?.user_state?.name,
      city: profile?.user_profile?.user_city?.name,
      about: profile?.user_profile?.about,
    });
    setUserData(profile);
    // GetSelfProfileAPi(Token)
    //   .then((res) => {
    //     const data = res?.data?.data;
    //     form.setFieldsValue({
    //       name: data?.user_profile?.name,
    //       username: data?.user_name,
    //       email: data?.email,
    //       mobile: data?.user_profile?.mobile,
    //       state: data?.user_profile?.user_state?.name,
    //       city: data?.user_profile?.user_city?.name,
    //       about: data?.user_profile?.about,
    //     });
    //     setSelectedState(data?.user_profile?.state_id);
    //     setSelectedCity(data?.user_profile?.city_id);
    //   })
    //   .catch((error) => {
    //     console.log(error, "error");
    //   });
  }, [profile]);
  const onFinish = (values) => {
    const headers = {
      "x-access-token": Token,
    };
    const formData = new FormData();
    if (selectedImage) {
      formData.append("images", selectedImage);
    }
    formData.append("name", values.name);
    formData.append("mobile", values.mobile);
    formData.append("state_id", selectedState);
    formData.append("city_id", selectedCity);
    formData.append("about", values.about);
    UpdateProfileApi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message)
      })
      .catch((error) => {
        console.log("Error updating profile:", error);
      });
  };
  const handleService = () => {
    // router.push("/beautician/serviceform");
    setIsModalVisible(true);
  };
  const handleProfilePhotoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };
  useEffect(() => {
    StateApi()
      .then((res) => {
        setStates(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const handleStateChange = (stateId) => {
    setSelectedState(stateId);
    CityApi(stateId)
      .then((res) => {
        setCities(res?.data?.data?.all_city);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Container className="editProfile">
      <div className="section1">
        <h4>Edit Profile</h4>
        <div className="btns">
          {/* <Button className="cancelBtn">Cancel</Button> */}
          {/* <Button className="doneBtn" htmlType="submit" form="editProfileForm">
            Submit
          </Button> */}
        </div>
      </div>
      <div className="section2">
        <div className="profile">
          {selectedImage === null ? (
            <img
              src={`${imageurl}${userData?.user_attachments[0]?.file_name}`}
              width={100}
              height={100}
              alt="Profile"
              className="Profile_top_images"
            />
          ) : (
            selectedImage && (
              <img
                src={URL.createObjectURL(selectedImage)}
                className="Profile_top_images"
                alt="Profile Pic"
                width={100} height={100}
              />
            )
          )}
          <p className="changePhoto" onClick={handleProfilePhotoClick}>
            Change Profile Photo
          </p>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        <Form form={form} id="editProfileForm" layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="User Name" name="username">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mobile Number" name="mobile">
                <Input placeholder="Enter mobile number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <label className="lable">State</label>
              <Form.Item name="state" rules={[{ required: true, message: "Please select a state!" }]}>
                <Select className="inputs" onChange={handleStateChange}>
                  {states?.map((state) => (
                    <Select.Option key={state.id} value={state.id}>
                      {state.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <label className="lable">City</label>
              <Form.Item name="city" rules={[{ required: true, message: "Please select a city!" }]}>
                <Select className="inputs" onChange={(value) => setSelectedCity(value)}>
                  {cities?.map((city) => (
                    <Select.Option key={city.id} value={city.id}>
                      {city.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Bio" name="about">
                <TextArea rows={4} className="inputs" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="submit">
            <Button htmlType="submit" className="doneBtn">
              Submit
            </Button>
          </Form.Item>
          <div>
            <h4>Services</h4>
            <div className="servies_list">
              {userData?.services.map((item, index) => {
                return (
                  <div key={index}>
                    <h6>{item?.name}</h6>
                    <hr />
                  </div>
                )
              })}
            </div>
            <div className="services" onClick={handleService}>
              <img
                src={addBtn}
                alt="Add Button"
                style={{ backgroundColor: "#25B8CB", borderRadius: "50%" }}
              />
              <p style={{ color: "#25B8CB", marginBottom: "0px", fontWeight: 700 }}>
                Add a new service...
              </p>
            </div>
          </div>
        </Form>
        <Modal
          // title="Add Service"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          centered
          width={900}
        >
          <ServiceForm setIsModalVisible={setIsModalVisible} />
        </Modal>
      </div>
    </Container>
  );
};
export default EditProfile;
