import React from "react";
import "./Services.scss";
const Services = ({ data }) => {
  return (
    <div className="service">
      <h6 style={{ marginBottom: "15px" }}>Services</h6>
      {data?.map((item, index) => {
        return (
          <div className="serviceDetails" key={index}>
            <p style={{ fontSize: "16px", fontWeight: 400 }}>{item?.name}</p>
            <p className="serviceCharge">${item?.price}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Services;
