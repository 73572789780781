// pages/profile/[id].jsx
import React  from "react";
import Services from "./Services/Services";
import "./Profile.scss";
import Posts from "./Posts/Posts";
import Layout from "../Layout.jsx/Layout";
import { FaArrowRight } from "react-icons/fa6";
import GridPhoto from "./GridPhoto/GridPhoto";
import GridReels from "./GridReels/GridReels";
const ProfilePage = ({ data, type }) => {
  return (
    <Layout data={data} type={type}>
      <div className="profileDetails">
        <p className="text">{data?.user_profile?.about}</p>
        <Services data={data?.services} type={type} />
        <GridPhoto data={data?.user_albums} type={type} />
        <GridReels data={data?.user_reels} type={type}  />
        <div className="PostSection">
          <h6>Posts</h6>
          <Posts data={data?.posts} type={type}  profileName={data?.user_profile?.name} profileImage={data?.user_attachments[0]?.file_name}
          
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ marginRight: "20px", fontWeight: "600" }}>
            Show all Posts
          </span>
          <FaArrowRight fontSize={20} />
        </div>
        {/* <GridPhoto />
      <Reviews /> */}
      </div>
    </Layout>
  );
};
export default ProfilePage;
