import React, { useEffect, useRef, useState } from "react";
// import { Container } from "react-bootstrap";
import { LuUpload } from "react-icons/lu";
import { Input, Form, Button, TimePicker, message, Select } from "antd";
import "./ServiceForm.scss";
import moment from "moment";
import { AddServicesApi, GetCategoryApi } from "../../apis/api";
import { useDispatch } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
const ServiceForm = ({ setIsModalVisible }) => {
  const [form] = Form.useForm();

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [category, setCategory] = useState([]);
  const Token = localStorage.getItem("TOKEN");
  const dispatch = useDispatch();

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click event
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file.name);
    }
  };
  const onFinish = (values) => {
   
    const headers = {
      "x-access-token": Token,
    };
    const formData = new FormData();
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("duration", values.duration.format("HH:mm"));
    formData.append("category_id", values.category);
    AddServicesApi(formData, headers)
      .then((res) => {
        message.success("Service updated successfully!");
        dispatch(fetchSelfProfile(Token));
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((error) => {
        message.error("Failed to update service.");
      });
  };
  useEffect(() => {
    GetCategoryApi(Token)
      .then((res) => {
        setCategory(res?.data?.data?.rows);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [Token]);
  return (
    <div className="serviceForm">
      <div className="header">
        <h3>Service Form</h3>
        <Button
          type="primary"
          htmlType="submit"
          form="serviceForm"
          className="savebtn"
        >
          Save Service
        </Button>
      </div>
      <Form
        id="serviceForm"
        onFinish={onFinish}
        className="form"
        layout="vertical"
        form={form}
      >
        <div className="headersection">
          <div className="uploadImageSection">
            <LuUpload fontSize={25} onClick={handleUploadClick} />
            <div className="btns">
              <button
                type="button"
                className="uploadbtn"
                onClick={handleUploadClick}
              >
                Upload document
              </button>
              <button
                type="button"
                className="removebtn"
                onClick={() => {
                  setSelectedFile(null);
                  setSelectedFileName("");
                }}
              >
                Remove
              </button>
            </div>
          </div>
          {selectedFileName && <p className="text">{selectedFileName}</p>}
          <p className="text">
            You can upload images showcasing your services.
          </p>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange} // Handle file input change
          />
        </div>
        <div className="inputSection">
          <Form.Item
            label="Service Name"
            name="name"
            rules={[{ required: true, message: "Please enter service name" }]}
          >
            <Input
              size="large"
              placeholder="Enter service name"
              className="inputs"
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please enter service description" },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter service description"
              className="inputs"
            />
          </Form.Item>
          <Form.Item
            label="Service Category"
            name="category"
            rules={[
              { required: true, message: "Please select a service type" },
            ]}
          >
            <Select size="large" placeholder="Select service type">
              {category?.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input size="large" placeholder="Enter price" className="inputs" />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true, message: "Please enter duration" }]}
          >
            <TimePicker
              format="HH:mm"
              size="large"
              className="inputs"
              style={{ width: "100%" }}
              minuteStep={30}
              defaultOpenValue={moment("00:00", "HH:mm")}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default ServiceForm;
