import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logoImg from "../../assest/Logo.png";
import "./Navbar.scss";
import { Dropdown, DropdownButton } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FaRegBell } from "react-icons/fa";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import DummyUser from "../../assest/dunmmyuser.png"
// import Notifications from "../Notifications/Notifications";
import { useNavigate } from "react-router-dom";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../apis/host";
function NavbarSection() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isNotification, setIsNotification] = useState(false);
  const Token = localStorage.getItem("TOKEN");
  // const userType = localStorage.getItem("myValue");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleLoginClick = (userType) => {
    localStorage.removeItem("myValue");
    localStorage.setItem("myValue", userType === "user" ? 6 : 7);
    navigate("/login");
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("myValue");
    navigate("/");
  };
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  const profile = useSelector(state => state?.user?.profile?.data);
  return (
    <>
      <div className="Navbar">
        <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
          <Container>
            <Navbar.Brand href="/">
              {" "}
              <img src={logoImg} width={100} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {/* {isClient && Token &&  */}
              <>
                <Nav.Link
                  onClick={() => {
                    const path = "/home";
                    navigate(path);
                  }}
                >
                  {" "}
                  HOME
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    const path = "/explore";
                    navigate(path);
                  }}
                >
                  EXPLORE
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    const path = "/showreel";
                    navigate(path);
                  }}
                >
                  REELS
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    const path = "/bookings";
                    navigate(path);
                  }}
                >
                  BOOKINGS
                </Nav.Link>
                {/* <Nav.Link onClick={() => {
                      const path = "/beautician-profile";
                      navigate(path)
                    }} >BEAUTICIAN PROFILE</Nav.Link> */}
                <Nav.Link
                  onClick={() => {
                    const path = "";
                    navigate(path);
                  }}
                >
                  INBOX
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    const path = "";
                    navigate(path);
                  }}
                >
                  <FaRegBell size={20} onClick={() => setIsNotification(!isNotification)} />
                </Nav.Link>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    p
                    style={{ margin: 0 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                     {profile?.user_attachments?.length === 0 ?
                        <img
                            src={DummyUser}
                            alt="Profile"
                            className="profilePhoto"
                            width={50} height={100}
                        /> : <img
                            src={`${imageurl}${profile?.user_attachments[0]?.file_name}`}
                            width={100}
                            height={100}
                            alt="Profile"
                            className="profilePhoto"
                        />
                    }
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      // handleClose;
                      const path = "/profile";
                      navigate(path);
                      // handleClosesidebar();
                    }}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      // handleClose();
                      handleLogout();
                      // handleClosesidebar()
                    }}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
              {/* } */}
              {!Token && (
                <>
                  <DropdownButton
                    id="dropdown-button-dark-example2"
                    title="Login"
                    className="ButtonTheme2"
                    style={{ padding: "0px", backgroundColor: "transparent" }}
                  >
                    <Dropdown.Item onClick={() => handleLoginClick("user")}>
                      Login as User
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleLoginClick("beautician")}
                    >
                      Login as Beautician
                    </Dropdown.Item>
                  </DropdownButton>
                  <Nav.Link
                    onClick={() => {
                      const path = "/signup";
                      navigate(path);
                    }}
                  >
                    <span
                      className="ButtonTheme2"
                      style={{ padding: "6.6px 8px" }}
                    >
                      SiGN UP
                    </span>
                  </Nav.Link>
                </>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div >
      {/* {
        isNotification && <Notifications />
      } */}
    </>
  );
}
export default NavbarSection;
