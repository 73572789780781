import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Layout.scss"
import { useDispatch, useSelector } from 'react-redux'
import { fetchSelfProfile } from '../../redux/slices/userSlice'
import ProfileCard from '../Profile/ProfileCard/ProfileCard'
const Layout = ({ children, type, data }) => {

    return (
        <div className='Layout'>
            <Container>
                <Row>
                    <Col sm={3}>
                        <div>
                            <ProfileCard data={data} type={type} />
                        </div>
                    </Col>
                    <Col sm={8}>

                        {children}

                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Layout
