import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GetAllAlbum } from '../../apis/api';

// Async thunk to fetch all albums
export const fetchAllAlbums = createAsyncThunk(
    'album/fetchAllAlbums',
    async (token, { rejectWithValue }) => {

        const response = await GetAllAlbum(token);
        return response.data;
    }
);

// Create album slice
const albumSlice = createSlice({
    name: 'album',
    initialState: {
        albums: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAlbums.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAllAlbums.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.albums = action.payload;
            })
            .addCase(fetchAllAlbums.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default albumSlice.reducer;
