import React, { useEffect } from "react";

import "../Profile.scss";
import { useDispatch } from "react-redux";
import { fetchAllReels } from "../../../redux/slices/reelSlice";
import { imageurl } from "../../../apis/host";
import { useNavigate } from "react-router-dom";
import NodataImage from "../../../assest/nodata1.jpg";
import { HiOutlinePlusSm } from "react-icons/hi";
const GridReels = ({ data, type }) => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("TOKEN");
  useEffect(() => {
    if (Token) {
      dispatch(fetchAllReels(Token));
    }
  }, [dispatch, Token]);
  // const temp = useSelector((state) => state?.reel?.reel?.data);
  const reel = data;
  const navigate = useNavigate();
  const handleShowReel = (path, item) => {
    navigate(`/showreel/${path}`, {
      state: { data: item, reel },
    });
  };
  return (
    <div className="reels">
      <div className="section1">
        <h6 style={{ marginBottom: "0px" }}>Reel</h6>

        {type !== "beautician" && (
          <small onClick={() => navigate("/sharereel")} style={{cursor:"pointer"}}>
            <HiOutlinePlusSm /> Create New
          </small>
        )}
      </div>
      <div className="reelGrid">
        {reel?.length === 0 ? (
          <img src={NodataImage} alt="No data Found" />
        ) : (
          reel?.map((reel, index) => (
            <div className="reelContainer" key={index}>
              <div onClick={() => handleShowReel(reel?.file_name, reel)} style={{cursor:"pointer"}}>
                <img
                  src={`${imageurl}${reel?.thumbnail_file_name}`}
                  alt={`Reel ${index + 1}`}
                  className="reel"
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
export default GridReels;
