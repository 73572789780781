import React, { useEffect, useState } from 'react';
import "./CounslorList.scss";
import { Container } from 'react-bootstrap';
import { encode } from "base-64";
import DummyUser from "../../assest/dunmmyuser.png";
import { CounslorListApi } from '../../apis/api';
import { useNavigate } from 'react-router-dom';
import CommonBanner from '../CommonBanner/CommonBanner';
import { imageurl } from '../../apis/host';
import { IoMdArrowForward } from 'react-icons/io';

const CounslorList = () => {
    const [counslordata, setCounslordata] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const token = localStorage.getItem("TOKEN");
    const navigate = useNavigate();

    useEffect(() => {
        CounslorListApi(token)
            .then((res) => {
                setCounslordata(res.data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [token]);

    const handleProfileClick = (name, id) => {
        navigate(`/beautician-profile/${name}/${encode(id)}`);
    };

    const handleRidirection = () => {
        setShowAll(!showAll);
    };

    return (
        <div className="counslor">
            <CommonBanner 
                imageUrl="https://png.pngtree.com/thumb_back/fh260/back_our/20200701/ourmid/pngtree-pink-cute-beauty-product-advertising-background-image_345472.jpg" 
                text1="Style Vila" 
                text2="Et provident eos est dolore. Eum libero" 
            />
            <Container className="Beauty_main">
                <div className="main_counslor">
                    <div className='top_heading'>
                        <h1>Professionals near me</h1>
                        <div className='show' onClick={handleRidirection}>
                            {showAll ? 'show less' : 'show all'}<IoMdArrowForward />
                        </div>
                    </div>
                    {(showAll ? counslordata : counslordata.slice(0, 3)).map((item, index) => (
                        <div className="card" key={index} onClick={() => handleProfileClick(item?.user_profile?.name, item.id)} style={{ cursor: "pointer" }}>
                            <div className="card-body">
                                <div className="top">
                                    <div>
                                        <img
                                            src={item?.user_attachments?.length === 0 ? DummyUser : `${imageurl}${item?.user_attachments[0]?.file_name}`}
                                            alt="Profile"
                                            className="profilePhoto"
                                            width={80}
                                            height={80}
                                        />
                                    </div>
                                    <div>
                                        <h5 className="card-title">{item?.user_profile?.name}</h5>
                                        <h6>{item?.email}</h6>
                                    </div>
                                </div>
                                <div className='aboutus'>
                                    {item?.user_profile?.about}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default CounslorList;
