import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import "./Explore.scss";
import { Dropdown, Menu } from "antd";
// import Photo1 from "../../assest/Photos/Photo1.png";
// import Photo2 from "../../assest/Photos/Photo2.png";
// import Photo3 from "../../assest/Photos/Photo3.png";
import { GetStoryApi } from "../../apis/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import Layout from "../../Components/Layout.jsx/Layout";
import { useNavigate } from "react-router-dom";
import Posts from "../../Components/Profile/Posts/Posts";
import { fetchfeed } from "../../redux/slices/feedSlice";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import Login from "../../Components/AuthLayout/Login/Login";
const Explore = () => {
  const Token = localStorage.getItem("TOKEN");
  // const [dataFeed, setDataFeed] = useState()
  // const [dataStory, setDataStory] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchfeed(Token));
  }, [dispatch, Token]);
  const dataFeed = useSelector((state) => state?.feed?.feed?.data);
  useEffect(() => {
    GetStoryApi(Token)
      .then((res) => {
        // setDataStory(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [Token]);
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  const profile = useSelector((state) => state?.user?.profile?.data);
  // const storiesData = [
  //   {
  //     id: 1,
  //     user: "John",
  //     image: Photo1,
  //   },
  //   {
  //     id: 2,
  //     user: "Alice",
  //     image: Photo2,
  //   },
  //   {
  //     id: 3,
  //     user: "Alice",
  //     image: Photo3,
  //   },
  //   {
  //     id: 4,
  //     user: "Alice",
  //     image: Photo1,
  //   },
  //   {
  //     id: 5,
  //     user: "Alice",
  //     image: Photo1,
  //   },
  //   {
  //     id: 6,
  //     user: "Alice",
  //     image: Photo2,
  //   },
  //   {
  //     id: 7,
  //     user: "Alice",
  //     image: Photo3,
  //   },
  //   {
  //     id: 8,
  //     user: "Alice",
  //     image: Photo1,
  //   },
  //   {
  //     id: 9,
  //     user: "Alice",
  //     image: Photo2,
  //   },
  //   {
  //     id: 10,
  //     user: "Alice",
  //     image: Photo3,
  //   },
  //   {
  //     id: 11,
  //     user: "Alice",
  //     image: Photo2,
  //   },
  //   {
  //     id: 12,
  //     user: "Alice",
  //     image: Photo1,
  //   },
  // ];
  const handleMenuClick = ({ key }) => {
    navigate(`/${key}`);
  };
  // const handleRedirect = ({ key }) => {};
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="sharepost">Create Post</Menu.Item>
      <Menu.Item key="sharereel">Create Reels</Menu.Item>
    </Menu>
  );
  return (
    <>
      {Token ? (
        <Layout data={profile} type="self">
          <div className="explore">
            <div className="top_heading">
              <h2>Explore</h2>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="createPost"
              >
                <button className="createPostBtn">
                  <PlusOutlined /> Create New
                </button>
              </Dropdown>
            </div>
            {/* <div className="Stories_section">
              <Stories storiesData={storiesData} />
            </div> */}
            <div className="Feed_section">
              <Posts data={dataFeed} passby="explore" />
            </div>
          </div>
        </Layout>
      ) : (
        <AuthLayout>
          <Login />
        </AuthLayout>
      )}
    </>
  );
};
export default Explore;
