import React from 'react'
import "./CommonBanner.scss"
import HomeBannerImage from "../../assest/home_banner.jpg"
import { Button, Container } from 'react-bootstrap'
const CommonBanner = ({ imageUrl, text1, text2, overlayColor }) => {
  return (
    <div className="CommonBanner">
      <div className="bannerContainer" style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className="overlay" />
        <Container>
          <div className="bannerText">
            <h3>
              {text1}
            </h3>
            <h5>{text2}</h5>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default CommonBanner
