import React, { useEffect, useState } from "react";
import { TimePicker, Select, Button, Input, message } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import profileImage from "../../assest/profile.png";
import "./Availability.scss";
import { AddManageAvailablity } from "../../apis/api";
import moment from 'moment'; // Import moment for time formatting
import Layout from "../Layout.jsx/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";

const { Option } = Select;

const Availability = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [maximumAvailableSeats, setMaximumAvailableSeats] = useState("");
  const [timeSlots, setTimeSlots] = useState([{ startTime: null, endTime: null }]);
  const [timeSlotButton, setTimeSlotButton] = useState(true);
  const [numAvailabilities, setNumAvailabilities] = useState(1);
  const Token = localStorage.getItem("TOKEN");

  const handleDayChange = (index) => (value) => {
    const newSelectedDays = [...selectedDays];
    newSelectedDays[index] = value;
    setSelectedDays(newSelectedDays);
  };

  const handleSeatsChange = (event) => {
    setMaximumAvailableSeats(event.target.value);
  };

  const handleTimeChange = (index, type) => (time) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][type] = time;
    setTimeSlots(newTimeSlots);
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: null, endTime: null }]);
    setTimeSlotButton(true);
  };

  const handleRemoveTimeSlot = (index) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots.splice(index, 1);
    setTimeSlots(newTimeSlots);
  };

  const handleSave = (index) => {
    const lastTimeSlot = timeSlots[timeSlots.length - 1];
    const newStartTime = lastTimeSlot.startTime;
    const newEndTime = lastTimeSlot.endTime;

    if (!selectedDays[index] || !newStartTime || !newEndTime) {
      message.error("Please complete all fields before saving.");
      return;
    }

    // Check for overlapping time slots
    for (let i = 0; i < timeSlots.length - 1; i++) {
      const startTime = timeSlots[i].startTime;
      const endTime = timeSlots[i].endTime;

      if (
        startTime.isBefore(newEndTime) &&
        endTime.isAfter(newStartTime)
      ) {
        message.error("New time slots overlap with existing ones.");
        return;
      }
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const availabilityData = {
      slot_duration_in_minuites: "30",
      timezone: timezone,
      start_time: newStartTime.format("hh:mm A"), // Format time to AM/PM
      end_time: newEndTime.format("hh:mm A"), // Format time to AM/PM
      day: selectedDays[index],
      maximum_available_seats: maximumAvailableSeats,
    };

    AddManageAvailablity(availabilityData, Token)
      .then((res) => {
        if (res.data.success) {
          message.success("Availability Created Successfully.");
          setTimeSlotButton(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Please add new time slot")
      });
  };

  const handleAddAvailability = () => {
    setNumAvailabilities(numAvailabilities + 1);
    setSelectedDays([...selectedDays, ""]);
  };

  const format = 'h:mm A';

  const renderAvailabilities = () => {
    return Array.from({ length: numAvailabilities }, (_, index) => (
      <div key={index} className="availabilityItem">
        <div className="formGroup">
          <label>Select a weekday:</label>
          <Select
            value={selectedDays[index]}
            onChange={handleDayChange(index)}
            style={{ width: "100%", marginBottom: 15 }}
          >
            <Option value="">-- Select --</Option>
            <Option value="Monday">Monday</Option>
            <Option value="Tuesday">Tuesday</Option>
            <Option value="Wednesday">Wednesday</Option>
            <Option value="Thursday">Thursday</Option>
            <Option value="Friday">Friday</Option>
            <Option value="Saturday">Saturday</Option>
            <Option value="Sunday">Sunday</Option>
          </Select>
        </div>
        <div className="formGroup">
          <label htmlFor={`maximumAvailableSeats${index}`}>
            Maximum Available Seats:
          </label>
          <Input
            id={`maximumAvailableSeats${index}`}
            value={maximumAvailableSeats}
            onChange={handleSeatsChange}
            type="number"
            style={{ width: "100%", marginBottom: 15 }}
          />
        </div>
        {timeSlots.map((timeSlot, timeIndex) => (
          <div key={timeIndex} className="formGroup">
            <label>Time Slot {timeIndex + 1}:</label>
            <div className="timeSlot">
              <TimePicker
                value={timeSlot.startTime}
                onChange={handleTimeChange(timeIndex, "startTime")}
                style={{ marginRight: 10 }}
                format={format} // Use AM/PM format
                use12Hours
              />
              <TimePicker
                value={timeSlot.endTime}
                onChange={handleTimeChange(timeIndex, "endTime")}
                format={format} // Use AM/PM format
                use12Hours
              />
              <Button
                type="danger"
                onClick={() => handleRemoveTimeSlot(timeIndex)}
                style={{ marginLeft: 20 }}
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
        <Button
          type="primary"
          onClick={() => handleSave(index)}
          style={{ marginTop: 30 }}
        >
          Submit
        </Button>
        <Button
          type="primary"
          onClick={handleAddTimeSlot}
          style={{ marginTop: 10, marginLeft: 20 }}
          disabled={timeSlotButton}
        >
          Add Time Slot
        </Button>
      </div>
    ));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  const profile = useSelector(state => state?.user?.profile?.data);

  return (<>

    <Layout data={profile} type="self">
      <div className="availability">
        <div className="header">
          <div className="d-flex align-items-center justify-content-center">
            <FaArrowLeft fontSize={22} />
            <h3 style={{ marginLeft: "10px" }}>Manage Availability</h3>
          </div>
          {/* <button className="savebtn">Save</button> */}
        </div>

        {renderAvailabilities()}
        <Button
          type="primary"
          onClick={handleAddAvailability}
          style={{ marginTop: 20 }}
          disabled={timeSlotButton}
        >
          Add New Availability
        </Button>
      </div>
    </Layout>
  </>
  );
};

export default Availability;
