import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GetAllReel } from '../../apis/api';

// Async thunk to fetch all albums
export const fetchAllReels = createAsyncThunk(
    'album/fetchAllreels',
    async (token, { rejectWithValue }) => {

        const response = await GetAllReel(token);
        return response.data;
    }
);

// Create album slice
const albumSlice = createSlice({
    name: 'reel',
    initialState: {
        reel: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReels.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAllReels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reel = action.payload;
            })
            .addCase(fetchAllReels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default albumSlice.reducer;
