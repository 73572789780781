import React, { useState } from "react";
import "./Login.scss";
import { Button, Form, Input, message, Radio } from "antd";
import logoImg from "../../../assest/Logo.png";
import { useNavigate } from "react-router-dom";
import { LoginAPi } from "../../../apis/api";
const Login = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 767);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   handleResize();
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const onFinish = (values) => {
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = {
      timezone: systemTimeZone,
      role_id: 7,
    };
    const formData = {
      email: values?.username,
      password: values?.password,
    };
    LoginAPi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message);
        localStorage.removeItem("myValue");
        localStorage.setItem("myValue", res?.data?.data?.role_id);
        let Token = res?.data?.data?.tokens?.access?.token;
        if (Token) {
          localStorage.removeItem("TOKEN");
          localStorage.setItem("TOKEN", Token);
        }
        navigate("/home");
      })
      .catch((e) => {
        console.log(e);
        message.error(e?.response?.data?.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [selectedRole, setSelectedRole] = useState(
    "beautician"
  );
  const onChange = (e) => {
    setSelectedRole(e.target.value);
    if (e.target.value === "user") {
      window.location.href = "https://user.glamlink.net/";
    } else if (e.target.value === "beautician") {
      window.location.href = "https://biz.glamlink.net/";
    }
  };
  return (
    <div className="Login">
      <img src={logoImg} width={150} alt="logo" />
      <h3>Welcome Back!</h3>
      <p className="para">
        We are so happy to see you again. You can continue logging in to your
        account.
      </p>
      <Radio.Group
        onChange={onChange}
        value={selectedRole}
        className="Radio_btn"
      >
        <Radio value="user" className="input_custom">
          User
        </Radio>
        <Radio value="beautician" className="input_custom">
          Beautician
        </Radio>
      </Radio.Group>
      <div className="form">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input
              className="input_custom"
              placeholder="example123@gmail.com"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password className="input_custom" placeholder="********" />
          </Form.Item>
          <Form.Item>
            <Button
              className="btn_theme"
              type="primary"
              htmlType="submit"
              block
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
        <p
          className="forgot_pass"
          onClick={() => {
            navigate("/forgot-password");
          }}
        >
          forgot password?
        </p>
      </div>
      <div className="bottom_text">
        New here?{" "}
        <span
          onClick={() => navigate("/signup")}
          style={{ cursor: "pointer", color: "#25B8CB" }}
        >
          Sign up
        </span>
      </div>
    </div>
  );
};
export default Login;
