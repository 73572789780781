import React from "react";
import loginImg from "../../assest/login.png";
import "./AuthLayout.scss";
const AuthLayout = ({ children }) => {
  return (
    <div className="Authlayout">
      <div className="Authlayout_Main">
        <div className="leftSide">
          <div className="imageOverlay"></div>
          <img src={loginImg} alt="login" className="loginImg" />
        </div>
        <div className="rightSide">{children}</div>
      </div>
    </div>
  );
};
export default AuthLayout;
