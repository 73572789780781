import React, { useState } from "react";
import "./Verify.scss";
import loginImg from "../../assest/login.png";
import { Col, Row, Button, Form , message } from "antd";
import logoImg from "../../assest/Logo.png";
import { VerifyAPi } from "../../apis/api";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
const Verify = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const onFinish = (values) => {
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = {
      timezone: systemTimeZone,
      role_id: 7
    };
    const formData = {
      email: email,
      otp: otp,
      type: "email_varification"
    };
    VerifyAPi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message)
        navigate("/login")
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onFinishFailed = (errorInfo) => {
  };
  return (
    <div className="Verify">
      <Row gutter={[16, 16]}>
        <Col span={14}>
          <div className="leftSide">
            <img src={loginImg} alt="login" className="loginImg" />
            <div className="imageOverlay"></div>
          </div>
        </Col>
        <Col span={10}>
          <div className="rightSide">
            <img className="logo" src={logoImg} width={150} alt="logo" />
            {/* <h1>Welcome Back!</h1>
            <p className="para">
              We are so happy to see you again. You can continue logging in to
              your account.
            </p> */}
            <div className="form">
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 25 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props) => <input
                    {...props}
                    style={{
                      width: "100%",
                      height: "40px",
                      fontSize: "18px",
                      textAlign: "center",
                      color: "white",
                      background: "#25B8CB",
                      border: "none",
                      borderRadius: "5px",
                    }} />}
                />
                <Button
                  className="ButtonTheme"
                  style={{ margin: "20px 0px" }}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Sign in
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Verify;
