// slices/beauticianSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetBeauticianProfileApi } from '../../apis/api';
export const fetchBeauticianProfile = createAsyncThunk(
  'beautician/fetchBeauticianProfile',
  async ({ userId, token }) => {
    const response = await GetBeauticianProfileApi(userId, token);
    return response.data;
  }
);
const beauticianSlice = createSlice({
  name: 'beautician',
  initialState: {
    loading: false,
    profile: null,
    success: false,
    error: null,
  },
  reducers: {
    // Optional: Define additional synchronous actions here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBeauticianProfile.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(fetchBeauticianProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(fetchBeauticianProfile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || 'Something went wrong';
      });
  },
});
export default beauticianSlice.reducer;
