import logo from './logo.svg';
import './App.css';
import NavbarSection from './Components/Navbar/Navbar';
import WebRouter from './WebRouter';
import { useLocation } from 'react-router-dom';
import FooterSection from './Components/Footer/Footer';
function App() {
  const location = useLocation();
  const hideNavbarFooter = ["/", '/login', '/signup', '/createaccount'].includes(location.pathname);
  return (
    <>
      {!hideNavbarFooter && <NavbarSection />}
        <WebRouter />
      {!hideNavbarFooter && <FooterSection />}
    </>
  );
}
export default App;
