// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import followReducer from "./slices/followSlice"
import beauticianprofileReducer from "./slices/beauticianSlice"
import feedreducer from "./slices/feedSlice.js"
import bookingsReducer from "./slices/bookingsSlice"
import albumReducer from "./slices/albumSlice.js"
import reelReducer from "./slices/reelSlice.js"
export const store = configureStore({
  reducer: {
    user: userReducer,
    follow: followReducer,
    beauticianprofile: beauticianprofileReducer,
    feed: feedreducer,
    bookings: bookingsReducer,
    album: albumReducer, 
    reel: reelReducer, 
  }
});
