import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { videourl } from "../../apis/host";
import "./ShowReel.scss";
import {
  FaCommentDots,
  FaHeart,
  FaRegCommentDots,
  FaRegHeart,
  FaShare,
} from "react-icons/fa";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllReels } from "../../redux/slices/reelSlice";
import { reelLike } from "../../apis/api";

const Showreel = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const videoRef = useRef(null);
  const { url } = useParams();
  const location = useLocation();
  const { data, reel } = location.state || [];
  const dispatch = useDispatch();
  const Token = localStorage.getItem("TOKEN");

  const onVideoPress = () => {
    if (isVideoPlaying) {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    } else {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const handleReelLike = (reel_id) => {
    reelLike(reel_id, Token)
      .then((res) => {
        dispatch(fetchAllReels(Token));
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const toggleDescription = (index) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="Showreel">
      <div className="app__videos">
        {url && (
          <div className="videoContainer">
            <video
              ref={videoRef}
              onClick={onVideoPress}
              className="videoCard__player"
              src={`${videourl}${url}`}
              alt="IG reel video"
              width="100%"
              loop
            />
            <div className="videoButtons">
              <div className="likeButton">
                <div
                  onClick={() => handleReelLike(data.id)}
                  style={{ cursor: "pointer" }}
                >
                  {data?.reel_likes?.length === 0 ? (
                    <FaHeart color="white" size={20} />
                  ) : (
                    <FaHeart color="red" size={20} />
                  )}
                </div>
                <h6>{data?.likes_count}</h6>
              </div>
              <div className="commentButton">
                <FaCommentDots color="white" size={20} />
                <h6>{data?.comment_count}</h6>
              </div>
              <div className="shareButton">
                <FaShare color="white" size={20} />
                <h6>Share</h6>
              </div>
            </div>
            <div className="videofooter">
              <h6>
                {expandedDescriptions[data.id] ? data.description : data.description.slice(0, 100) + (data.description.length > 100 ? "..." : "")}
                {data.description.length > 100 && (
                  <span
                    onClick={() => toggleDescription(data.id)}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {expandedDescriptions[data.id] ? " See less" : " See more"}
                  </span>
                )}
              </h6>
              <div className="namedate">
                <h6>{data?.reel_user?.user_profile?.name}</h6>
                <span>{moment(data?.created_at).format("D MMMM YYYY")}</span>
              </div>
            </div>
          </div>
        )}
        {reel
          ?.filter((item) => item.id !== data?.id)
          ?.map((item2, index) => {
            return (
              <div className="videoContainer" key={index}>
                <video
                  ref={videoRef}
                  onClick={onVideoPress}
                  className="videoCard__player"
                  src={`${videourl}${item2?.file_name}`}
                  alt="IG reel video"
                  width="100%"
                  loop
                />
                <div className="videoButtons">
                  <div className="likeButton">
                    <div
                      onClick={() => handleReelLike(item2.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {item2?.reel_likes?.length === 0 ? (
                        <FaHeart color="white" size={20} />
                      ) : (
                        <FaHeart color="red" size={20} />
                      )}
                    </div>
                    <h6>{item2?.likes_count}</h6>
                  </div>
                  <div className="commentButton">
                    <FaCommentDots color="white" size={20} />
                    <h6>{item2?.comment_count}</h6>
                  </div>
                  <div className="shareButton">
                    <FaShare color="white" size={20} />
                    <h6>Share</h6>
                  </div>
                </div>
                <div className="videofooter">
                  <h6>
                    {expandedDescriptions[item2.id] ? item2.description : item2.description.slice(0, 100) + (item2.description.length > 100 ? "..." : "")}
                    {item2.description.length > 100 && (
                      <span
                        onClick={() => toggleDescription(item2.id)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {expandedDescriptions[item2.id] ? " See less" : " See more"}
                      </span>
                    )}
                  </h6>
                  <div className="namedate">
                    <h6>{item2?.reel_user?.user_profile?.name}</h6>
                    <span>{moment(item2?.created_at).format("D MMMM YYYY")}</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Showreel;
