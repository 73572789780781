import React, { useEffect } from 'react'
import ProfilePage from '../Profile/Profile'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelfProfile } from '../../redux/slices/userSlice';

const SelfProfile = () => {
  const Token = localStorage.getItem("TOKEN");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSelfProfile(Token));
  }, [dispatch, Token]);
  const profile = useSelector(state => state?.user?.profile?.data);
  return (
    <div>
      <ProfilePage type="self" data={profile}/>
    </div>
  )
}

export default SelfProfile
