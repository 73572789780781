import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./albumPage.scss";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { Modal, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Layout from '../Layout.jsx/Layout';
import { fetchSelfProfile } from '../../redux/slices/userSlice';
import { fetchBeauticianProfile } from '../../redux/slices/beauticianSlice';
import { decode } from 'base-64';
import { imageurl } from '../../apis/host';
// import { AddPictureinAlbum } from '../../apis/api';
const AlbumPage = () => {
    const Token = localStorage.getItem("TOKEN");
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const { id } = useParams();
    const [visible, setVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    useEffect(() => {
        if (id) {
            const decodedId = decode(id);
            dispatch(fetchBeauticianProfile({ userId: decodedId, Token }));
        } else {
            dispatch(fetchSelfProfile(Token));
        }
    }, [dispatch, Token, id]);
    const userData = useSelector((state) => state?.beauticianprofile?.profile?.data);
    const profile = useSelector(state => state?.user?.profile?.data);
    const location = useLocation();
    const { data } = location?.state;
    const showModal = () => {
        setVisible(true);
    };
    const handleCancel = () => {
        setVisible(false);
        setFileList([]);
    };
    const handleUpload = async () => {
        if (fileList.length > 0) {
            setUploading(true);
            try {
                const albumId = data?.id; // Assume that data contains album details including id
                const formData = new FormData();
                formData.append('images', fileList[0].originFileObj); // Assuming single file upload
                formData.append('album_id', albumId);
                // const res = await AddPictureinAlbum(formData, Token);
                dispatch(fetchSelfProfile(Token));
                message.success('Upload successful');
                navigate(-1)
                setVisible(false);
                setFileList([]);
                // Refresh the album data after successful upload
                if (id) {
                    const decodedId = decode(id);
                    dispatch(fetchBeauticianProfile({ userId: decodedId, Token }));
                } else {
                    dispatch(fetchSelfProfile(Token));
                }
            } catch (error) {
                console.error("Error uploading photo:", error);
                message.error('Upload failed');
            } finally {
                setUploading(false);
            }
        }
    };
    // const handleUpload = async () => {
    //     if (fileList.length > 0) {
    //         setUploading(true);
    //         try {
    //             const albumId = data?.id; // Assume that data contains album details including id
    //             const formData = new FormData();
    //             formData.append('images', fileList[0].originFileObj); // Assuming single file upload
    //             formData.append('album_id', albumId);
    //             const res = await AddPictureinAlbum(formData, Token);
    //             dispatch(fetchSelfProfile(Token));
    //             message.success('Upload successful');
    //             setVisible(false);
    //             setFileList([]);
    //             // Refresh the album data after successful upload
    //             if (id) {
    //                 const decodedId = decode(id);
    //                 dispatch(fetchBeauticianProfile({ userId: decodedId, Token }));
    //             } else {
    //                 dispatch(fetchSelfProfile(Token));
    //             }
    //         } catch (error) {
    //             console.error("Error uploading photo:", error);
    //             message.error('Upload failed');
    //         } finally {
    //             setUploading(false);
    //         }
    //     }
    // };
    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };
    return (
        <div className='albumPage'>
            <Layout data={id ? userData : profile} type={id ? "beautician" : "self"}>
                <div className='top'>
                    <h6>{data?.title}</h6>
                    {!id && (
                        <h6 className='uploadpicture' onClick={showModal}>
                            <HiOutlinePlusSm /> Upload Photo
                        </h6>
                    )}
                </div>
                <div className="photoGrid">
                    {data?.album_attachments?.map((photo, index) => (
                        <div className="photoContainer" key={index}>
                            <img
                                src={`${imageurl}${photo?.file_name}`}
                                alt={`Album ${index + 1}`}
                                className="photo"
                                width="100"
                            />
                        </div>
                    ))}
                </div>
            </Layout>
            <Modal
                visible={visible}
                title="Upload Photo"
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={uploading}
                        onClick={handleUpload}
                    >
                        {uploading ? 'Uploading...' : 'Upload'}
                    </Button>,
                ]}
            >
                <Upload
                    fileList={fileList}
                    beforeUpload={() => false}
                    onChange={handleFileChange}
                    multiple={false}
                    listType="picture"
                >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            </Modal>
        </div>
    );
};
export default AlbumPage;
