import React from "react";
import Services from "./Services/Services";
import "./Profile.scss";
import Posts from "./Posts/Posts";
import Layout from "../Layout.jsx/Layout";
import GridPhoto from "./GridPhoto/GridPhoto";
import GridReels from "./GridReels/GridReels";
// import Reviews from "./Reviews/Reviews";
const ProfileBeautician = ({ data, type }) => {
  return (
    <Layout data={data} type={type}>
      <div className="profileDetails">
        <p className="text">{data?.user_profile?.about}</p>
        <Services data={data?.services} type={type} />
        <GridPhoto data={data?.user_albums} type={type} />
        <GridReels data={data?.user_reels} type={type} />
        <div className="PostSection">
          <h6>Posts</h6>
          <Posts data={data?.posts} type={type} profileName={data?.user_profile?.name} profileImage={data?.user_attachments[0]?.file_name}/>
        </div>
        {/* <Reviews /> */}
        {/* 
      <Reviews /> */}
      </div>
    </Layout>
  );
};
export default ProfileBeautician;
