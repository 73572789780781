import axios from "axios";
const BASE_URL = "https://node.glamlink.net:5000/api/v1/";
// get Value by localstorage
export const getLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem(key);
        // return null
    }
    return null;
};
// remove Value by localstorage
export const removeLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        return localStorage.removeItem(key);
    }
    return null;
};
export const GetblogsApi = async () => {
    return await axios.get(`${BASE_URL}blog?page=1&limit=5&sortBy=`)
}
export const BlogDetails = async (blog_id) => {
    return await axios.get(`${BASE_URL}blog/${blog_id}`)
}
export const GetFaqs = async () => {
    return await axios.get(`${BASE_URL}faq`)
}
export const ContactUsApi = async (contactUsData) => {
    return await axios.post(`${BASE_URL}contactUs`, contactUsData);
};
export const SalonAPi = async (city) => {
    return await axios.get(`${BASE_URL}user/find-beautician?place=${city}&item=salon`);
}
// StateApi
export const StateApi = async () => {
    return await axios.get(`${BASE_URL}state`);
}
// cityApi
export const CityApi = async (state_id) => {
    return await axios.get(`${BASE_URL}state/${state_id}`);
}
// login
export const LoginAPi = (formData, headers) => {
    return axios.post(`${BASE_URL}user/auth/login`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        }
    });
};
// RegisterAPi
export const RegisterAPi = (formData, headers) => {
    return axios.post(`${BASE_URL}user/auth/register`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        }
    });
};
// sendOtpAPi
export const sendOtpAPi = (formData, headers) => {
    return axios.post(`${BASE_URL}user/auth/otp`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        }
    });
};
// VerifyApi
export const VerifyAPi = (formData, headers) => {
    return axios.post(`${BASE_URL}user/auth/verify-otp`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        }
    });
};
// ForgotPasswordApi
export const ForgotPasswordApi = (formData, headers) => {
    return axios.post(`${BASE_URL}user/auth/forgot-password`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        }
    });
};
// getCatergory
export const GetCategoryApi = async (token) => {
    return await axios.get(`${BASE_URL}category?page&limit=100&sortBy`, {
        headers: {
            "x-access-token": token
        }
    });
}
// AddServicesApi
export const AddServicesApi = async (formData, headers) => {
    return await axios.post(`${BASE_URL}service`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        }
    });
};
// AddPost
export const AddPostApi = (formData, headers) => {
    return axios.post(`${BASE_URL}post`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        }
    });
};


// post report

export const PostReport = async (formadata, token) => {
    return await axios.post(`${BASE_URL}post/report`, formadata, {
      headers: {
        "x-access-token": token,
      },
    });
  };
  
// counslor list
export const CounslorListApi = async (token) => {
    return await axios.get(`${BASE_URL}user/list/random-counsellors?limit=10&page=1`, {
        headers: {
            "x-access-token": token
        }
    });
}
// get profile api
export const GetBeauticianProfileApi = async (id, token) => {
    return await axios.get(`${BASE_URL}user/profile/${id}`, {
        headers: {
            "x-access-token": token
        }
    });
}
export const GeoCodingApi = async (address) => {
    return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyBwWrA2Zwyk_XXqBzGdp7B1Bt9mXCLG5CA`, {
    });
}
// getSelfUser Profile
export const GetSelfProfileAPi = async (token) => {
    return await axios.get(`${BASE_URL}user/profile`, {
        headers: {
            "x-access-token": token
        }
    });
}
// update profile
export const UpdateProfileApi = (formData, headers) => {
    return axios.post(`${BASE_URL}user/profile`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        }
    });
};
// AddServicesApi
export const AddManageAvailablity = (formData, token) => {
    return axios.post(`${BASE_URL}counselor/schedular/availability`, formData, {
        headers: {
            'x-access-token': token
        }
    });
};
export const FollowApi = async (formData, token) => {
    return await axios.post(`${BASE_URL}user/follow`, formData, {
        headers: {
            "x-access-token": token
        }
    });
}
// get Feeds
export const GetFeedApi = async (token) => {
    return await axios.get(`${BASE_URL}user/feed?limit=5&page=1&sortBy=DESC`, {
        headers: {
            "x-access-token": token
        }
    });
}
// get story
export const GetStoryApi = async (token) => {
    return await axios.get(`${BASE_URL}user/wall?limit=5&page=1&sortBy=DESC`, {
        headers: {
            "x-access-token": token
        }
    });
}
// post like
export const PostLike = async (value, token) => {
    let config = {
        post_id: value
    }
    return await axios.post(`${BASE_URL}post/like`, config, {
        headers: {
            "x-access-token": token
        }
    });
}
export const CreateAlbum = async (value, token) => {
    let config = {
        title: value
    }
    return await axios.post(`${BASE_URL}album`, config, {
        headers: {
            "x-access-token": token
        }
    });
}
export const AddPictureinAlbum = async (formdata, token) => {
    // let config = {
    //     images: value1,
    //     album_id: value2
    // }
    return await axios.post(`${BASE_URL}user/images`, formdata, {
        headers: {
            "x-access-token": token
        }
    });
}
export const GetAllAlbum = async (token) => {
    return await axios.get(`${BASE_URL}album?page&limit&sortBy`, {
        headers: {
            "x-access-token": token
        }
    });
}

// AddPost
export const AddReel = (formData, headers) => {
    return axios.post(`${BASE_URL}reel`, formData, {
        headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        }
    });
};
export const GetAllReel = async (token) => {
    return await axios.get(`${BASE_URL}reel`, {
        headers: {
            "x-access-token": token
        }
    });
}

export const reelLike = async (value, token) => {
    let config = {
        reel_id: value
    }
    return await axios.post(`${BASE_URL}reel/like`, config, {
        headers: {
            "x-access-token": token
        }
    });
}

export const GetAppointment = async (token, key) => {
    return await axios.get(`${BASE_URL}appointment?timeStatus=${key}`, {
        headers: {
            "x-access-token": token,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    });
}
export const AcceptRequest = async (token, id) => {
    let config = {
        appointment_id: id
    }
    return await axios.post(`${BASE_URL}appointment/accept`, config, {
        headers: {
            "x-access-token": token,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    });
}
export const RejectRequest = async (token, text, id) => {
    let config = {
        reason: text
    }
    return await axios.put(`${BASE_URL}appointment/cancel/${id}`, config, {
        headers: {
            "x-access-token": token,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    });
}

export const FollowersApi = async (token, id) => {
    return await axios.get(`${BASE_URL}user/followers?user_id=${id}`, {
      headers: {
        "x-access-token": token,
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  };
  
  export const FollowingApi = async (token, id) => {
    return await axios.get(`${BASE_URL}user/followees?user_id=${id}`, {
      headers: {
        "x-access-token": token,
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  };
  
  export const RemoveFollowerApi = async (token, id) => {
    return await axios.delete(`${BASE_URL}user/followers?user_id=${id}`, {
      headers: {
        "x-access-token": token,
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  };



  // user report

export const UserReport = async (formadata, token) => {
    return await axios.post(`${BASE_URL}user/profile/report`, formadata, {
      headers: {
        "x-access-token": token,
      },
    });
  };
  
  // user report
  
  export const BlockUser = async (formadata, token) => {
    return await axios.post(`${BASE_URL}user/profile/block`, formadata, {
      headers: {
        "x-access-token": token,
      },
    });
  };