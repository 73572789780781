import React, { useEffect, useState } from "react";
import { AiOutlineMore, AiOutlineLike, AiFillLike } from "react-icons/ai";
import { FaRegBookmark, FaRegCommentDots } from "react-icons/fa6";
import "./Post.scss";
import { PiShareFatThin } from "react-icons/pi";
import { imageurl } from "../../../apis/host";
import { useDispatch } from "react-redux";
import { fetchfeed } from "../../../redux/slices/feedSlice";
import { fetchSelfProfile } from "../../../redux/slices/userSlice";
import { PostLike, PostReport } from "../../../apis/api";
import NodataImage from "../../../assest/nodata1.jpg";
import LikeImage from "../../../assest/like.png";
import { Dropdown, Menu, Modal, Input, Button, message } from "antd";
import moment from "moment";
const Posts = ({ data, type, profileName, profileImage, passby }) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportPostId, setReportPostId] = useState(null);
  const Token = localStorage.getItem("TOKEN");
  const handlepostLike = (postid) => {
    PostLike(postid, Token)
      .then((res) => {
        dispatch(fetchfeed(Token));
        dispatch(fetchSelfProfile(Token));
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  useEffect(() => {
    dispatch(fetchfeed(Token));
    dispatch(fetchSelfProfile(Token));
  }, [Token, dispatch]);
  const handleMenuClick = ({ key }) => {
    if (key === "reportpost") {
      setModalVisible(true);
    }
  };
  // const profile = useSelector((state) => state?.user?.profile?.data);
  const handleReportPost = () => {
    const formData = {
      post_id: reportPostId,
      reason: reportReason,
    };
    PostReport(formData, Token)
      .then((res) => {
        message.success(res.data.message);
        setModalVisible(false);
        setReportReason("");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="reportpost">Report Post</Menu.Item>
      {/* <Menu.Item key="reportuser">Report User</Menu.Item> */}
    </Menu>
  );
  return (
    <div className="Post">
      {data?.length === 0 ? (
        <img src={NodataImage} alt="No data Found" />
      ) : (
        data?.map((item, index) => {
          return (
            <div className="Post_inner" key={index}>
              <div className="top">
                <div className="top_inner">
                  <div>
                    {passby === "explore" && (
                      <img
                        src={
                          item?.created_by?.user_attachments.length === 0
                            ? "https://cdn-icons-png.flaticon.com/512/6522/6522516.png"
                            : `${imageurl}${item?.created_by?.user_attachments[0]?.file_name}`
                        }
                        alt=""
                        className="profileimage"
                      />
                    )}
                    {passby !== "explore" && (
                      <img
                        src={
                          item?.profileImage === undefined
                            ? "https://cdn-icons-png.flaticon.com/512/6522/6522516.png"
                            : `${imageurl}${profileImage}`
                        }
                        alt=""
                        className="profileimage"
                      />
                    )}
                  </div>
                  <div>
                    {passby === "explore" && (
                      <h6>{item?.created_by?.user_profile?.name}</h6>
                    )}
                    {passby !== "explore" && <h6>{profileName}</h6>}
                    <h6 className="hours">
                      {moment(item?.created_at).fromNow()}
                    </h6>
                  </div>
                </div>
                {type !== "self" && (
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    className="createPost"
                  >
                    <AiOutlineMore
                      fontSize={30}
                      style={{ cursor: "pointer" }}
                      onClick={() => setReportPostId(item.id)}
                    />
                  </Dropdown>
                )}
              </div>
              {item?.attachements && item?.attachements?.length !== 0 && (
                <div className="ImageSection">
                  <img
                    src={`${imageurl}${item?.attachements[0]?.file_name}`}
                    alt="Profile"
                    className="post_img"
                  />
                </div>
              )}
              <div className="contentSection">
                <p>{item?.content}</p>
              </div>
              <div className="media">
                <div className="hit_media_section">
                  <div className="Like">
                    <h6>
                      <img src={LikeImage} alt="like" width={20} height={20} />{" "}
                      {item?.likes_count}
                    </h6>
                  </div>
                  <div className="Comment">
                    <h6>{item?.comment_count} Comment</h6>
                  </div>
                </div>
                <div className="socilmediaSection">
                  <div className="Like" onClick={() => handlepostLike(item.id)}>
                    <div>
                      {item?.likes?.length === 0 ? (
                        <AiOutlineLike />
                      ) : (
                        <AiFillLike color="blue" />
                      )}
                    </div>
                    <h6>Like</h6>
                  </div>
                  <div className="Comment">
                    <div>
                      <FaRegCommentDots />
                    </div>
                    <h6>Comment</h6>
                  </div>
                  <div className="Share">
                    <div>
                      <PiShareFatThin />
                    </div>
                    <h6>Share</h6>
                  </div>
                  <div className="Send">
                    <div>
                      <FaRegBookmark />
                    </div>
                    <h6>Send</h6>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
      <Modal
        title="Report Post"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleReportPost}>
            Submit
          </Button>,
        ]}
      >
        <Input.TextArea
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
          placeholder="Enter the reason for reporting this post"
          rows={4}
        />
      </Modal>
    </div>
  );
};
export default Posts;
