import React, { useState } from 'react';
import axios from 'axios';

const Account = () => {
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  const GeoCodingApi = (address) => {
    return axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: address,
        format: 'json',
      },
    });
  };

  const handleAddressChange = (e) => {
    const address = e.target.value;
    setAddress(address);

    if (address) {
      GeoCodingApi(address)
        .then((res) => {
          if (res.data.length === 0) {
            setError("Please enter a correct address");
            setLatitude(null);
            setLongitude(null);
          } else {
            const location = res.data[0];
            setLatitude(location.lat);
            setLongitude(location.lon);
            setError(null);
          }
        })
        .catch((e) => {
          console.log(e);
          setError("Error fetching data");
          setLatitude(null);
          setLongitude(null);
        });
    } else {
      setError("Address is required");
      setLatitude(null);
      setLongitude(null);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Geocode Address</h1>
      <input
        type="text"
        value={address}
        onChange={handleAddressChange}
        placeholder="Enter address"
        style={{ width: '300px', padding: '10px' }}
      />
      {latitude && longitude && (
        <div style={{ marginTop: '20px' }}>
          <h2>Coordinates</h2>
          <p>Latitude: {latitude}</p>
          <p>Longitude: {longitude}</p>
        </div>
      )}
      {error && (
        <div style={{ marginTop: '20px', color: 'red' }}>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default Account;
