import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  CityApi,
  RegisterAPi,
  StateApi,
  GeoCodingApi,
} from "../../../apis/api";
import logoImg from "../../../assest/Logo.png";
import "./CreateAccount.scss";
const { TextArea } = Input;
const { Option } = Select;
const CreateAccount = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  // const [isMobile, setIsMobile] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitute] = useState("");
  useEffect(() => {
    const handleResize = () => {
      // setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    StateApi()
      .then((res) => {
        setStates(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity("");
    setCities([]);
    CityApi(value)
      .then((res) => {
        setCities(res?.data?.data?.all_city);
      })
      .catch((e) => {
        console.log(e);
        setCities([]);
      });
  };
  // const fetchAddressCoordinates = async (address) => {
  //   try {
  //     const response = await GeoCodingApi(address);
  //     const { lat, lng } = response.results[0].geometry.location;
  //     setAddressCoordinates({ latitude: lat, longitude: lng });
  //   } catch (error) {
  //     console.error('Error fetching address coordinates:', error);
  //     message.error('Error fetching address coordinates. Please try again.');
  //   }
  // };
  const handleAddressChange = (e) => {
    const address = e.target.value;
    GeoCodingApi(address)
      .then((res) => {
        if (res?.data.status === "ZERO_RESULTS") {
          message.error("please Enter Correct Address");
        }
        if (res?.data?.status === "OK") {
          setLatitude(res.data?.results[0].geometry.location.lat);
          setLongitute(res.data?.results[0].geometry.location.lng);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onFinish = async (values) => {
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = {
      timezone: systemTimeZone,
      role_id: 7,
    };
    const formData = new FormData();
    if (selectedImage) {
      formData.append("images", selectedImage);
    }
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("address", values?.address);
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    formData.append("mobile", values?.mobile);
    formData.append("password", values?.password);
    formData.append("confirm_password", values?.confirmPassword);
    formData.append("about", values?.bio);
    formData.append("state_id", selectedState);
    formData.append("city_id", selectedCity);
    RegisterAPi(formData, headers)
      .then((res) => {
        message.success(res?.data?.message);
        navigate(`/verify-account?email=${encodeURIComponent(values.email)}`);
      })
      .catch((error) => {
        console.log(error, "error");
        message.error(error?.response?.data?.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const removeImage = () => {
    setSelectedImage("");
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        message.error("You can only upload JPG, JPEG, or PNG files.");
        return;
      }
      setSelectedImage(file);
    }
  };
  return (
    <div className="createAccount">
      <div className="containt">
        <img className="logo" src={logoImg} width={150} alt="logo" />
        <h3>Create Your Account</h3>
      </div>
      <div className="form">
        <p className="lable">Profile picture</p>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 25 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          {/* Profile Picture Upload */}
          <div className="profileForm">
            <div className="profiletop">
              {selectedImage === "" ? (
                <img
                  src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                  alt=""
                  className="Profile_top_images"
                  style={{ borderRadius: "100px" }}
                />
              ) : (
                selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    className="Profile_top_images"
                    alt="Profile Pic"
                    style={{ borderRadius: "50px" }}
                  />
                )
              )}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button size="larg" style={{ marginRight: "20px" }}>
                <label className="custom_file_upload">
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleFileChange}
                  />
                  Upload image
                </label>
              </Button>
              <Button size="larg" onClick={removeImage}>
                Remove
              </Button>
              <p style={{ marginTop: "10px" }} className="lable">
                You can upload jpg, gif, or png image files. Max size of 3mb.
              </p>
            </div>
          </div>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input className="inputs" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input className="inputs" />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[{ required: true, message: "Please input your mobile!" }]}
          >
            <Input className="inputs" />
          </Form.Item>
          <Space>
            <Form.Item
              label="State"
              name="state"
              rules={[{ message: "Please select a state!" }]}
            >
              <Select
                showSearch
                className="inputs"
                onChange={handleStateChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {states?.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[{ message: "Please select a city!" }]}
            >
              <Select
                showSearch
                className="inputs"
                onChange={(value) => setSelectedCity(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cities?.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ message: "Please input your address!" }]}
          >
            <Input onChange={handleAddressChange} />
          </Form.Item>
          <Form.Item
            label="BIO"
            name="bio"
            rules={[{ required: true, message: "Please input your Bio!" }]}
          >
            <TextArea rows={4} className="inputs" />
          </Form.Item>
          <Form.Item
            label="Create Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 8,
                message: "Password must be at least 8 characters long",
              },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                message:
                  "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
              },
            ]}
          >
            <Input.Password className="inputs" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password className="inputs" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn_theme">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default CreateAccount;
