import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NavbarSection from "./Components/Navbar/Navbar";
import FooterSection from "./Components/Footer/Footer";
import Explore from "./Pages/Explore/Explore";
import Layout from "./Components/Layout.jsx/Layout";
import NewRequestCard from "./Pages/NewRequest/NewRequestCard";
import Bookings from "./Pages/Bookings/Bookings";
import EditProfile from "./Components/EditProfile/EditProfile";
import ProfilePage from "./Components/Profile/Profile";
import SharePost from "./Components/SharePost/SharePost";
import AvailabilityContainer from "./Components/Availability/Availability";
import CounslorList from "./Components/CounslorList/CounslorList";
import BeauticianProfile from "./Components/BeauticianProfile/BeauticianProfile";
import SelfProfile from "./Components/SelfProfile/SelfProfile";
import Try from "./Components/Profile/try";
import Verify from "./Components/Verify/Verify";
import AllCounslorList from "./Components/CounslorList/AllCounslorList";
import Account from "./Components/CreateAccount/account";
import AlbumPage from "./Components/albumPage/albumPage";
import ShowReel from "./Components/ShowReel/ShowReel";
import AuthLayout from "./Components/AuthLayout/AuthLayout";
import Login from "./Components/AuthLayout/Login/Login";
import { SignUp } from "./Components/AuthLayout/SignUp/SignUp";
import CreateAccount from "./Components/AuthLayout/CreateAccount/CreateAccount";
import ShareReel from "./Components/ShareReel/ShareReel";
import AllReels from "./Components/AllReels";
import Showreel from "./Components/ShowReel/ShowReel";
const WebRouter = () => {
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />
        <Route
          exact
          path="/signup"
          element={
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          }
        />
        <Route
          exact
          path="/createaccount"
          element={
            <AuthLayout>
              <CreateAccount />
            </AuthLayout>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />

        <Route exact path="/account" element={<Account />} />

        <Route exact path="/edit-profile" element={<EditProfile />} />
        <Route exact path="/sharepost" element={<SharePost />} />
        <Route exact path="/sharereel" element={<ShareReel />} />

        <Route exact path="/try" element={<Try />} />
        <Route exact path="/verify-account" element={<Verify />} />
        {/* <Route exact path="/profile" element={<ProfilePage />} /> */}
        <Route
          exact
          path="/manage-availability"
          element={<AvailabilityContainer />}
        />
        <Route exact path="/profile" element={<SelfProfile />} />
        <Route
          exact
          path="/profile/album-section/:title/:id?"
          element={<AlbumPage />}
        />
        <Route
          exact
          path="/beautician-profile/:name/:id"
          element={<BeauticianProfile />}
        />
        <Route exact path="/explore" element={<CounslorList />} />
        <Route
          exact
          path="/allrofessionalslist"
          element={<AllCounslorList />}
        />
        <Route exact path="/showreel/:url" element={<Showreel/>} />
        <Route exact path="/showreel" element={<AllReels />} />

        <Route exact path="/home" element={<Explore />} />
        <Route exact path="/bookings" element={<Bookings />} />
      </Routes>
    </div>
  );
};
export default WebRouter;
