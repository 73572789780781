import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import "./ProfileCard.scss";
import { imageurl } from "../../../apis/host";
import DummyUser from "../../../assest/dunmmyuser.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchBeauticianProfile } from "../../../redux/slices/beauticianSlice";
import { followUser } from "../../../redux/slices/followSlice";
import { decode } from "base-64";
import { Dropdown, Input, Menu, Modal, message } from "antd";
import { AiOutlineMore } from "react-icons/ai";
import {
  BlockUser,
  FollowersApi,
  FollowingApi,
  RemoveFollowerApi,
  UserReport,
} from "../../../apis/api";
// import { retry } from "@reduxjs/toolkit/query";
const ProfileCard = ({ data, type }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportUserId, setReportUserId] = useState(null);
  const [modalAction, setModalAction] = useState("");
  const [followersModalVisible, setFollowersModalVisible] = useState(false);
  const [followingModalVisible, setFollowingModalVisible] = useState(false);
  const { id } = useParams();
  const token = localStorage.getItem("TOKEN");
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (id && token) {
      const decodedId = decode(id);
      setUserId(decodedId);
    }
  }, [id,token]);
  const handleFollow = () => {
    const formData = {
      followee_id: userId,
    };
    dispatch(followUser({ formData, token }))
      .then(() => {
        dispatch(fetchBeauticianProfile({ userId: userId, token }));
      })
      .catch((error) => {
        console.error("Error following user:", error);
      });
  };
  const navigate = useNavigate();
  // const handleRedirection = (path) => {
  //   if (path === "edit-profile") {
  //     navigate(`/${path}`);
  //   } else {
  //     navigate(`/${path}/${name}/${id}`);
  //   }
  // };
  const hanldeRedirection = (path) => {
    navigate(`/${path}`);
  };
  const handleMenuClick = ({ key }) => {
    if (key === "reportpost") {
      setModalAction("reportUser");
      setModalVisible(true);
    } else if (key === "blockuser") {
      setModalAction("blockUser");
      setModalVisible(true);
    }
  };
  const handleReportUser = () => {
    const formData = {
      user_id: reportUserId,
      reason: reportReason,
    };
    UserReport(formData, token)
      .then((res) => {
        console.log(res, "reportt");
        message.success(res.data.message);
        setModalVisible(false);
        setReportReason("");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleBlockUser = () => {
    const formData = {
      user_id: reportUserId,
      reason: reportReason,
    };
    BlockUser(formData, token)
      .then((res) => {
        message.success(res.data.message);
        setModalVisible(false);
        setReportReason("");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleSubmit = () => {
    if (modalAction === "reportUser") {
      handleReportUser();
    } else if (modalAction === "blockUser") {
      handleBlockUser();
    }
  };
  const openFollowersModal = () => {
    setFollowersModalVisible(true);
  };
  const openFollowingModal = () => {
    setFollowingModalVisible(true);
  };
  const closeFollowersModal = () => {
    setFollowersModalVisible(false);
  };
  const closeFollowingModal = () => {
    setFollowingModalVisible(false);
  };
  const [follower, setFollower] = useState();
  const [following, setFollowing] = useState();
  useEffect(() => {
    FollowersApi(token, data?.id)
      .then((res) => {
        setFollower(res.data.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [token, data?.id]);
  useEffect(() => {
    FollowingApi(token, data?.id)
      .then((res) => {
        setFollowing(res.data.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [token, data?.id]);

  const handleRemoveFollower = (id) => {
    RemoveFollowerApi(token, id)
      .then((res) => {
      })
      .catch((e) => {
        console.log(e, "e");
      });
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="reportpost">Report User</Menu.Item>
      <Menu.Item key="blockuser">Block User</Menu.Item>
    </Menu>
  );
  return (
    <div className="ProfileCard">
      <Card body>
        <div className="ProfileCard_inner">
          {type === "beautician" && (
            <Dropdown overlay={menu} trigger={["click"]} className="createPost">
              <AiOutlineMore
                fontSize={30}
                style={{ cursor: "pointer", position: "absolute", right: 0 }}
                onClick={() => setReportUserId(data.id)} // Assuming item has user_id
              />
            </Dropdown>
          )}
          {data?.user_attachments?.length === 0 ? (
            <img
              src={DummyUser}
              alt="Profile"
              className="profilePhoto"
              width={50}
              height={100}
            />
          ) : (
            <img
              src={`${imageurl}${data?.user_attachments[0]?.file_name}`}
              width={100}
              height={100}
              alt="Profile"
              className="profilePhoto"
            />
          )}
          <h5>{data?.user_profile?.name}</h5>
          <div className="post_follower_following">
            <div className="post">
              <div>{data?.user_profile?.no_of_post_posted}</div>
              <div>Posts</div>
            </div>
            <div
              className="followers"
              onClick={follower?.length === 0 ? null : openFollowersModal}
            >
              <div>{data?.user_profile?.follower_count}</div>
              <div>Followers</div>
            </div>
            <div
              className="following"
              onClick={following?.length === 0 ? null : openFollowingModal}
            >
              <div>{data?.user_profile?.followee_count}</div>
              <div>Following</div>
            </div>
          </div>
          {type !== "self" && (
            <div className="Follow_massage_btn">
              <Button className="follow_btn" onClick={handleFollow}>
                {data?.is_follow === false ? "Follow" : "Unfollow"}
              </Button>
              <Button className="follow_btn">Message</Button>
            </div>
          )}
          {type === "self" && (
            <>
            <Button
              className="edit_profile_btn"
              onClick={() => hanldeRedirection("edit-profile")}
              >
              Edit Profile
            </Button>
          <Button
            className="btn_theme"
          onClick={() => hanldeRedirection("manage-availability")}
        >
            Manage Availability
          </Button>
            </>
            )}
        </div>
      </Card>
      <Modal
        title="Report Post"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            className="follow_btn mdm_btn"
            onClick={() => setModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="follow_btn mdm_btn"
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Input.TextArea
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
          placeholder="Enter the reason for reporting this post"
          rows={4}
        />
      </Modal>
      <Modal
        title="Followers"
        visible={followersModalVisible}
        onCancel={closeFollowersModal}
        footer={null}
      >
        <div className="Following">
          {follower?.map((item, index) => {
            return (
              <Card key={index}>
                <div className="Following_detail">
                  <div>
                    <h6>
                      {item?.follow_user?.user_attachments.length === 0 ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/6522/6522516.png"
                          className="followin_picture"
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${imageurl}${item?.follow_user?.user_attachments[0]?.file_name}`}
                          alt="followin_picture"
                          className="followin_picture"
                        />
                      )}
                      {item?.follow_user?.user_profile?.name}
                    </h6>
                  </div>
                  <div>
                    <Button
                      className="edit_profile_btn"
                      onClick={() =>
                        handleRemoveFollower(
                          item?.followee_user?.user_profile.id
                        )
                      }
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </Modal>
      <Modal
        title="Following"
        visible={followingModalVisible}
        onCancel={closeFollowingModal}
        footer={null}
      >
        <div className="Following">
          {following?.map((item, index) => {
            return (
              <Card key={index}>
                <div className="Following_detail">
                  <div>
                    <h6>
                      {item?.followee_user?.user_attachments.length === 0 ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/6522/6522516.png"
                          className="followin_picture"
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${imageurl}${item?.followee_user?.user_attachments[0]?.file_name}`}
                          alt="followin_picture"
                          className="followin_picture"
                        />
                      )}
                      {item?.followee_user?.user_profile.name}
                    </h6>
                  </div>
                  <div>
                    <Button
                      className="edit_profile_btn"
                      onClick={() =>
                        handleRemoveFollower(
                          item?.followee_user?.user_profile.id
                        )
                      }
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};
export default ProfileCard;
