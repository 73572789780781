import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./SharePost.scss";
import { Input, Upload, message } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { fetchSelfProfile } from "../../redux/slices/userSlice";
import { AddPostApi } from "../../apis/api";
import { imageurl } from "../../apis/host";
import ImgCrop from "antd-img-crop";
const { TextArea } = Input;
const SharePost = () => {
    const Token = localStorage.getItem("TOKEN");
    const [photoFiles, setPhotoFiles] = useState([]);
    const [text, setText] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchSelfProfile(Token));
    }, [dispatch, Token]);
    const profile = useSelector(state => state?.user?.profile?.data);
    // const loading = useSelector(state => state.user.loading);
    // const error = useSelector(state => state.user.error);
    // if (loading) {
    //     return <div>Loading...</div>;
    // }
    // if (error) {
    //     return <div>Error: {error}</div>;
    // }
    const handlePhotoChange = ({ fileList }) => {
        if (fileList.length > 1) {
            fileList = [fileList[fileList.length - 1]];
        }
        setPhotoFiles(fileList);
    };
    const handlePost = () => {
        const formData = new FormData();
        formData.append("content", text);
        photoFiles.forEach((file) => {
            formData.append("images", file.originFileObj);
        });
        const headers = {
            "x-access-token": Token,
        };
        AddPostApi(formData, headers)
            .then((response) => {
                message.success("Post added successfully");
                setText("");
                setPhotoFiles([]);
            })
            .catch((error) => {
                console.error("Error adding post:", error);
                message.error("Error adding post");
            });
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Add a Photo</div>
        </div>
    );
    const handleClearData = () => {
        setText("");
        setPhotoFiles([]);
    }
    return (
        <div className="sharePostContainer">
            <Container>
                <div className="top_heading">
                    <div>
                        <h3>Share Post</h3>
                    </div>
                    <div >
                        <button className="cancelBtn" onClick={handleClearData}>Cancel</button>
                        <button className="postBtn" onClick={handlePost}>
                            Post
                        </button>
                    </div>
                </div>
                <div className="sharePosts">
                    <div className="profile">
                        {profile?.user_attachments?.length === 0 ? (
                            <img
                                src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                                width={50}
                                height={50}
                                alt="Profile"
                                className="profilePhoto"
                            />
                        ) : (
                            <img
                                src={`${imageurl}${profile?.user_attachments[0]?.file_name}`}
                                width={50}
                                height={50}
                                alt="Profile"
                                className="profilePhoto"
                            />
                        )}
                        <div className="name">
                            <h5 style={{ fontWeight: 700 }}>{profile?.user_profile?.name}</h5>
                        </div>
                    </div>
                    <div className="text_area">
                        <TextArea
                            className="noBorderOnFocus border-0"
                            rows={5}
                            placeholder="What do you want to talk about?"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <div className="align-items-center mt-4">
                            <ImgCrop rotationSlider>
                                <Upload
                                    listType="picture-card"
                                    fileList={photoFiles}
                                    onChange={handlePhotoChange}
                                    beforeUpload={() => false}
                                >
                                    {photoFiles.length < 1 && uploadButton}
                                </Upload>
                            </ImgCrop>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default SharePost;
