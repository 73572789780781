import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decode } from 'base-64';
import { fetchBeauticianProfile } from '../../redux/slices/beauticianSlice';
import ProfileBeautician from '../Profile/ProfileBeautician';
const BeauticianProfile = () => {
    // const [userId, setUserId] = useState(null);
    const { id } = useParams()
    const token = localStorage.getItem('TOKEN');
    const dispatch = useDispatch();
    useEffect(() => {
        if (id && token) {
            const decodedId = decode(id);
            // setUserId(decodedId);
            dispatch(fetchBeauticianProfile({ userId: decodedId, token }));
        }
    }, [id, token, dispatch]);
    const userData = useSelector((state) => state?.beauticianprofile?.profile?.data);
    return (
        <div>
            <ProfileBeautician data={userData} type="beautician" />
        </div>
    )
}
export default BeauticianProfile
