import React from 'react'
import "./Footer.scss"
import { Container } from 'react-bootstrap'
import logoImg from "../../assest/Logo.png"
import Facebook from "../../assest/socialMediaIcon/facebook.png"
import Instagram from "../../assest/socialMediaIcon/instagram.png"
import Twitter from "../../assest/socialMediaIcon/twitter.png"
import Youtube from "../../assest/socialMediaIcon/youtube.png"
import { useNavigate } from 'react-router-dom'
const FooterSection = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate()
    return (
        <div className="FooterSection">
            <Container>
                <div className="FooterMainSection">
                    <div className="Section1">
                        <img src={logoImg} width={100} alt='logo' />
                    </div>
                    <div className="Section2">
                        <h6 onClick={() => {
                            const path = "/aboutus";
                            navigate(path)
                        }}>About Us</h6>
                        <h6 onClick={() => {
                            const path = "/blogs";
                            navigate(path)
                        }}>
                            Blogs
                        </h6>
                        <h6 onClick={() => {
                            const path = "/faqs";
                            navigate(path)
                        }} >FAQs</h6>
                        <h6 >
                            BEAUTICIAN NEAR ME</h6>
                    </div>
                    <div className="Section3">
                        <div>
                            <img src={Facebook} width={40} alt="" />
                        </div>
                        <div>
                            <img src={Instagram} width={40} alt="" />
                        </div>
                        <div>
                            <img src={Twitter} width={40} alt="" />
                        </div>
                        <div>
                            <img src={Youtube} width={40} alt='' />
                        </div>
                    </div>
                </div>
                <hr style={{ color: "white" }} />
                {/* <div className="line">
                </div> */}
                <div className="FooterBottom">
                    <div>
                        <small>© {currentYear} YUME. All rights reserv</small>
                    </div>
                    <div>
                        <small>
                            <span className="cursor">
                                Terms of service
                            </span>
                            <span>
                                &nbsp; | &nbsp;
                            </span>
                            <span className="cursor"
                             onClick={() => {
                                // Open the desired URL
                                const url = "https://glamlink.net/privacy-policy";
                                window.open(url, "_blank");
                            }}
                            >
                                Privacy Policy
                            </span>
                        </small>
                    </div>
                </div>
            </Container>
        </div>
    )
}
export default FooterSection
