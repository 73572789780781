// followSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { FollowApi } from '../../apis/api';


// export const followUser = createAsyncThunk(
//   'follow/followUser',
//   async ({ formData, token}, thunkAPI) => {
//     try {
//       const response = await FollowApi(formData, token);
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

export const followUser = createAsyncThunk(
    'user/fetchSelfProfile',
    async ({formData,token}) => {
      const response = await FollowApi(formData,token);
    message.success(response.data?.message)
      return response?.data?.message;
    }
  );

const followSlice = createSlice({
  name: 'follow',
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    // Optional: Define additional synchronous actions here
  },
  extraReducers: (builder) => {
    builder
      .addCase(followUser.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(followUser.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(followUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || 'Something went wrong';
      });
  },
});

export default followSlice.reducer;
