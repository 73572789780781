import React, { useEffect, useState } from "react";
import { Button, Form, Radio, Space } from "antd";
import singUpImg1 from "../../../assest/pana.png";
import singUpImg2 from "../../../assest/pana1.png";
import logoImg from "../../../assest/Logo.png";
import "./SignUp.scss";
import { useNavigate } from "react-router-dom";
export const SignUp = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("Beautician");
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 767);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   handleResize();
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const handleImageChange = (e) => {
    setSelectedImage(e.target.value);
  };
  const onFinish = () => {
    localStorage.removeItem("myValue");
    localStorage.setItem("myValue", selectedImage === "user" ? 6 : 7);
    if (selectedImage === "user") {
      // Open the desired URL
      const url = "https://user.glamlink.net/createaccount";
      window.open(url, "_blank");
    } else {
      navigate("/createaccount");
    }
  };
 
  return (
    <div className="Signup">
      <img src={logoImg} width={150} alt="logo" />
      <h3>What are you looking for?</h3>
      <p className="para">Choose Sign up type</p>
      <Radio.Group
        onChange={handleImageChange}
        value={selectedImage}
        className="RadioImages"
      >
        <Space direction="vertical">
          <Radio
            value="user"
            className={selectedImage === "user" ? "radio-selected" : ""}
            style={{width:"45%",position:"relative",background:"#f4f5f6",padding:"18px 50px",textAlign:"center"}}
          >
            <img src={singUpImg1} className="signupImg" alt="User" />
            <h6>User</h6>
          </Radio>
          <Radio
            value="Beautician"
            className={selectedImage === "Beautician" ? "radio-selected" : ""}
            style={{width:"45%",position:"relative",background:"#f4f5f6",padding:"18px 50px",textAlign:"center"}}


          >
            <img src={singUpImg2} className="signupImg" alt="Beautician" />
            <h6>Beautician</h6>
          </Radio>
        </Space>
      </Radio.Group>
      <Form.Item>
        <Button
          className="btn_theme"
          type="primary"
          htmlType="submit"
          block
          onClick={onFinish}
        >
          Continue
        </Button>
      </Form.Item>
    </div>
  );
};
